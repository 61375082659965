document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    hamburger_menu();
    navigation_bg();
    data_images();

    window.onload = function () {
      pageloader();
    };
  }
};

// Helper
/* Language Switcher */
const lngSwitchBtns = document.querySelectorAll('.lngSwitchBtn');
if (lngSwitchBtns.length > 0) {
  for (let i = 0; i < lngSwitchBtns.length; i++) {
    const lngButton = lngSwitchBtns[i];

    lngButton.addEventListener('click', (e) => {
      e.preventDefault();
      const lng = lngButton.dataset.lng;
      document.cookie = `i18next=${lng}; path=/; max-age=31536000`;

      window.setTimeout(() => {
        location.reload();
      }, 10);
    });
  }
}

/* SLIDE UP */
let slideUp = (target, duration = 500) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
    //alert("!");
  }, duration);
};

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;
  if (display === 'none') display = 'block';
  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
};

// -------------------------------------------------
// -----------  ANCHOR NAVIGATION ------------------
// -------------------------------------------------

var nav = new SlideNav();

// -------------------------------------------------
// -----------  GLIDER ------------------
// -------------------------------------------------

new Glide('.glide', {
  type: 'carousel',
  focusAt: 1,
  autoplay: 2000,
  hoverpause: true,
  startAt: 0,
  perView: 3,
  breakpoints: {
    900: {
      perView: 2,
      focusAt: 1,
    },
    600: {
      perView: 1,
      focusAt: 0,
    },
  },
}).mount();

// -----------------------------------------------------
// ---------------   PAGELOADER    --------------------
// -----------------------------------------------------

function pageloader() {
  'use strict';

  if (document.documentElement.scrollTop > 0) {
    document.querySelector('.navigation .topbar_inner').classList.add('opened');
  }

  var speed = 500;

  setTimeout(function () {
    document.body.classList.add('loaded');
  }, speed);
  setTimeout(function () {
    document.querySelector('.navigation').classList.add('loaded');
    color_hero_img();
  }, speed + 500);
}

// -----------------------------------------------------
// ---------------   DATA IMAGES    --------------------
// -----------------------------------------------------

function data_images() {
  'use strict';

  var data = document.querySelectorAll('*[data-img-url]');

  for (var i = 0; i < data.length; i++) {
    var url = data[i].getAttribute('data-img-url');
    data[i].style.backgroundImage = 'url(' + url + ')';
  }
}

// -----------------------------------------------------
// ---------------   COLOR HERO IMAGE    ---------------
// -----------------------------------------------------

function color_hero_img() {
  'use strict';

  var hero_img = document.getElementById('hero-img');
  if (hero_img) {
    hero_img.style.filter = 'grayscale(0)';
  }
}

// -----------------------------------------------------
// ---------------   MOBILE MENU    --------------------
// -----------------------------------------------------

function hamburger_menu() {
  'use strict';

  var hamburger = document.querySelector('.hamburger');
  var mobileMenu = document.querySelector('.mobile_navigation .dropdown');
  var mobileMenuLink = document.querySelector(
    '.mobile_navigation .dropdown .dropdown_inner ul li a'
  );

  hamburger.addEventListener('click', function () {
    if (hamburger.classList.contains('is-active')) {
      hamburger.classList.remove('is-active');
      slideUp(mobileMenu, 1000);
    } else {
      hamburger.classList.add('is-active');
      slideDown(mobileMenu, 1000);
    }
    return false;
  });

  mobileMenuLink.addEventListener('click', function () {
    hamburger.classList.remove('is-active');
    slideUp(mobileMenu, 1000);
    return false;
  });
}

// -----------------------------------------------------
// --------------   TOPBAR BACKGROUND    ---------------
// -----------------------------------------------------

function navigation_bg() {
  'use strict';

  window.addEventListener('scroll', function () {
    var topbar = document.querySelector('.navigation .topbar_inner');

    if (window.pageYOffset >= 100) {
      topbar.classList.add('opened');
    } else {
      topbar.classList.remove('opened');
    }
  });
}

// -------------------------------------------------
// -------------  PROGRESS BAR  --------------------
// -------------------------------------------------

function tdProgress(container) {
  'use strict';

  const elements = container.querySelectorAll('.progress_inner');

  for (var i = 0; i < elements.length; i++) {
    var progress = elements[i];
    var pValue = parseInt(progress.getAttribute('data-value'), 10);
    var pColor = progress.getAttribute('data-color');
    var pBarWrap = progress.querySelector('.bar');
    var pBar = progress.querySelector('.bar_in');

    pBar.style.width = pValue + '%';
    pBar.style.backgroundColor = pColor;
    delay(pBarWrap, i * 100);
  }
  function delay(pBarWrap, sec) {
    setTimeout(() => {
      pBarWrap.classList.add('open');
    }, 1000 + sec);
  }
}

const tokyo = document.querySelectorAll('.tokyo_progress');

for (var j = 0; j < tokyo.length; j++) {
  var pWrap = tokyo[j];

  var waypoint = new Waypoint({
    element: pWrap,
    handler: function () {
      tdProgress(pWrap);
    },
    offset: '90%',
  });
}

// Waypoints for resume

const we = document.querySelectorAll('.entry');

we.forEach(function (ele) {
  new Waypoint({
    element: ele,
    handler: function () {
      for (var i = 0; i < we.length; i++) {
        we[i].classList.remove('active');
      }
      ele.classList.add('active');
    },
    offset: '50%',
  });
});

// End Waypoints for resume

const contact_btn = document.getElementById('contact_btn');
const modal = document.getElementById('modal');
const closeBtn = document.querySelector('.close');

if (contact_btn) {
  //TODO: append form to modal

  contact_btn.addEventListener('click', () => {
    if (modal) {
      modal.classList.add('opened');
    }
  });
}

if (closeBtn) {
  closeBtn.addEventListener('click', () => {
    if (modal) {
      modal.classList.remove('opened');
    }
  });
}
